module.exports = {
  messages: {
    BUTTON_TRY_AGAIN: 'Noch einmal versuchen',
    CONTENT_ERROR_404_HEADLINE:
      'Es tut uns leid: Diese Seite ist leider wie vom Winde verweht.',
    CONTENT_ERROR_404_SUBLINE:
      "Aber hier gibt's garantiert etwas zu entdecken:",
    SYSM_WEB_ERROR_CTA: 'Zur XING Startseite',
    XE_GENERIC_ERROR:
      'Da ist etwas schiefgelaufen. Versuche es bitte noch einmal.',
  },
};
